// import SectionHeading from "../SectionHeading";
import { bubbleImg } from "../../utils/allImgs";

const TokenInfo = ({ img, dataUp, dataDown }) => {
  return (
    <div className=" section-padding-100 banner-bg relative" id="features">
      <div className="bubble-wrapper">
        <img src={bubbleImg} alt="" />
        <h2 className="coin-text">$TIGER</h2>
      </div>
      {/* <div className="container">
        <SectionHeading
          ClassSpanTitle="gradient-t green"
          titleUp="About Our Token"
          titleDown="Our Token Info"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo."
        />
        <div className="row align-items-center">
          <div className="col-12 col-lg-5 col-md-12">
            <img src={img} alt="" />
          </div>
          <div className="col-12 col-lg-7 col-sm-12">
            <div className="col-md-6 col-sm-6">
              <div className="who-we-contant mt-s">
                <ul className="token-information">
                  {dataUp &&
                    dataUp.map((item, key) => (
                      <li key={key}>
                        <span className={item.Num} />
                        <h6>{item.title}</h6>
                        <p className={`${item.isLast && "mb-0"}`}>
                          {item.text}
                        </p>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="who-we-contant mt-s">
                <ul className="token-information">
                  {dataDown &&
                    dataDown.map((item, key) => (
                      <li key={key}>
                        <span className={item.Num} />
                        <h6>{item.title}</h6>
                        <p className={`${item.isLast && "mb-0"}`}>
                          {item.text}
                        </p>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TokenInfo;

import "./Subscribe.scss";
import { bubbleImg } from "../../utils/allImgs";

const Subscribe = () => {
  return (
    <div className="section-padding-100 relative" id="burnToearn">
      <div className="bubble-wrapper">
        <img src={bubbleImg} alt="" />
        <h2 className="coin-text">$TIGER</h2>
      </div>
      <section className="container">
        <div className="subscribe section-padding-200-100 addMLInSmall">
          <div className="row">
            <div className="col-sm-12">
              <div className="subscribe-wrapper">
                <div className="section-heading text-center">
                  <h2 data-aos="fade-up" data-aos-delay="300">
                    Bucks.Market
                  </h2>
                  <p data-aos="fade-up" data-aos-delay="400">
                    A dedicated exchange for meme coins, is set to launch in
                    September 2024.
                  </p>
                </div>
                <div className="service-text text-center">
                  <button className="btn more-btn">Bucks.Market</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Subscribe;

import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import {
  HowToStartInfo,
  // OurTeamInfo,
} from "../../data/data-containers/HomeDemo3/data-HomeDemo3.js";

import dataTokenInfoUp from "../../data/data-containers/HomeDemo3/data-TokenInfoUp.json";
import dataTokenInfoDown from "../../data/data-containers/HomeDemo3/data-TokenInfoDown.json";
// import RoadmapInfo from "../../data/data-containers/HomeDemo3/data-RoadmapInfo.json";
// import FaqInfo from "../../data/data-containers/HomeDemo3/data-FaqInfo.json";
// import dataContact from "../../data/data-containers/HomeDemo3/data-contact.json";
import IcoName from "../../data/data-layout/Footer/data-IcoName.json";
import TextFooter from "../../data/data-layout/Footer/data-TextFooter.json";

import {
  HomeDemo3Computer1,
  HomeDemo3Trade,
  // HomeDemo3Payment,
  HomeDemo3Whitepaper1,
  // HomeDemo3Trade1,
  Hero3Imag,
  HomeDemo3Dist,
  // HomeDemo3Faq,
  FooterPattern,
  FooterLogo,
} from "../../utils/allImgs";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import SecHeroSection from "../../components/HeroSection";
import AboutUs from "../../components/AboutUs";
import SecToken from "../../components/SecToken";
import OurICO from "../../components/OurICO";
// import Roadmap from "../../components/Roadmap";
import TokenInfo from "../../components/TokenInfo";
import HowToStart from "../../components/HowToStart";
// import Faq from "../../components/Faq";
// import OurTeam from "../../components/OurTeam";
import Subscribe from "../../components/Subscribe";

const HomeDemo3 = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <div>
      <Header Title="Tiger Token | Home" />
      <SecHeroSection
        ClassSec="hero-section banner-bg section-padding mb-100"
        ColClass="col-12 col-lg-6 col-md-12"
        specialHead="Catch The Future and Hold More Tiger Token"
        textUp="$TIGER Future OF Meme Coins"
        textDown="$TIGER’s got one job: to back up the price of Bucks as its ARMY. Burn TIGER, get Bucks—it’s a game-changing move."
        link1="Whitepaper"
        link2="Buy Token"
        wrapperClass="hedo-wrapper floating-anim mt-s"
        img={Hero3Imag}
      />
      <AboutUs
        ClassSec="about-us-area section-padding-100-0 clearfix"
        Computer1={HomeDemo3Computer1}
        Trade={HomeDemo3Trade}
        titleUp="About the Bucks trading platform"
        titleDown="An exchange dedicated exclusively to meme coins"
        textUp="Bucks.Market allows users to easily generate tokens and list them directly on Bucks. With a wide variety of Dapps combinations available, it offers a high level of user satisfaction that you won’t find elsewhere."
        textDown="And all of this comes at a low cost."
        link="Bucks.Market"
      />
      <div className="clearfix" />
      <HowToStart data={HowToStartInfo} />
      <SecToken
        conuterHeader="The amount of $TIGER burned"
        title="0"
        strength="100b"
        pullLeft="Softcap in 103 days"
        pullRight="Token Hardcap"
        prePrice="$TIGER in circulation is being burned daily"
        // img={HomeDemo3Payment}
      />
      {/* <Roadmap
        data={RoadmapInfo}
        ClassSpanTitle="roadmap section-padding-0-0"
      /> */}
      <OurICO
        Whitepaper1={HomeDemo3Whitepaper1}
        // Trade1={HomeDemo3Trade1}
        title="TIGER Whitepaper"
        textUp="Downoad Our Whitepaper"
        textDown="Here is the TIGER Whitepaper. Please note that the Whitepaper is updated regularly without notice, so be sure to check it frequently. Thank you for your attention."
        link="Download Now"
      />
      {/* <TokenInfo
        img={HomeDemo3Dist}
        dataUp={dataTokenInfoUp}
        dataDown={dataTokenInfoDown}
      /> */}
      {/* <Faq
        ClassSpanTitle="faq-timeline-area section-padding-100-85"
        img={HomeDemo3Faq}
        data={FaqInfo}
      /> */}
      {/* <OurTeam
        data={OurTeamInfo}
        ClassSec="our_team_area section-padding-50-0 clearfix"
      /> */}
      <Subscribe />
      <Footer
        FooterPattern={FooterPattern}
        FooterLogo={FooterLogo}
        text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit ducimus voluptatibus neque illo id repellat quisquam? Autem expedita earum quae laborum ipsum ad."
        IcoName={IcoName}
        TextFooter={TextFooter}
        // dataContact={dataContact}
      />
    </div>
  );
};

export default HomeDemo3;

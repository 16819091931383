import "./Wellcome.scss"

const SecHeroSection = ({
      ClassSec="",
      ColClass="",
      specialHead="",
      textUp="",
      textDown="",
      link1="",
      link2="",
      img="",
      wrapperClass=""

    }) => {

  return (
    <section className={ClassSec} id="home">
      {/* Hero Content */}
      <div className="hero-section-content">
        <div className="container ">
          <div className="row align-items-center">
            {/* Welcome Content */}
            <div className={ColClass}>
              <div className="welcome-content">
                <div className="promo-section">
                  <h3 className="special-head">{specialHead}</h3>
                </div>
                <h1 data-wow-delay="0.2s">{textUp}</h1>
                <p className="w-text">{textDown}</p>
                <div className="dream-btn-group" data-wow-delay="0.4s">
                  <a href="#" className="btn more-btn mr-3 addMarBtnLink">{link1}</a>
                  <a href="#" className="btn more-btn">{link2}</a>
                </div>
              </div>
            </div>
              <div className="col-lg-6">
                  <div className={wrapperClass}>
                    <img draggable="false" src={img} alt="" />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecHeroSection;
import {
  HomeDemo3Icons1,
  HomeDemo3Icons2,
  HomeDemo3Icons3,
  HomeDemo3TeamImg1,
  HomeDemo3TeamImg2,
  HomeDemo3TeamImg3,
  HomeDemo3TeamImg4,
} from "../../../utils/allImgs";

export const HowToStartInfo = [
  {
    img: HomeDemo3Icons1,
    num: 1,
    title: "Available on CEX and DEX",
    text: "It's available on MEXC, Coinstore, Bitcastle, and more. Additionally, you can buy it on PancakeSwap for DEX.",
  },
  {
    img: HomeDemo3Icons2,
    num: 2,
    title: "Earn with BurnToEarn!",
    text: "You can get locked $TIGER coins with great terms on the BurnToEarn platform. Highly recommended!",
  },
  {
    img: HomeDemo3Icons3,
    num: 3,
    title: "You get Bucks from TIGER.",
    text: "To obtain Bucks, you need to BurnToEarn TIGER. That's why TIGER is essential.",
  },
];

export const OurTeamInfo = [
  {
    img: HomeDemo3TeamImg1,
    title: "Joman Helal",
    text: "Executive Officer",
  },
  {
    img: HomeDemo3TeamImg2,
    title: "Slans Alons",
    text: "Business Development",
  },
  {
    img: HomeDemo3TeamImg3,
    title: "Josha Michal",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo3TeamImg4,
    title: "Johan Wright",
    text: "Head of Marketing",
  },
];

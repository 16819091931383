import { useState } from "react";
import "./AboutUs.scss";
import { symbolImg } from "../../utils/allImgs";

const AboutUs = ({
  ClassSec = "about-us-area section-padding-100 banner-bg addMLInSmall mt-50",
  Computer1,
  Trade,
  titleUp,
  titleDown,
  textUp,
  textDown,
  link,
}) => {
  const [copied, setCopied] = useState(false);
  const copyContractAddr = () => {
    navigator.clipboard.writeText("0x0345FAB41E60e71bE6c80D279bf6396f46D630f1");
    setCopied(true);
  };

  return (
    <section className={ClassSec} id="about">
      <div className="container">
        <div className="d-inline-block">
          <div className="contract-add align-items-center">
            {copied ? (
              <i className="fa fa-check" onClick={copyContractAddr}></i>
            ) : (
              <i className="fa fa-copy" onClick={copyContractAddr}></i>
            )}
            <h6 className="mr-3 mb-0">Smart Contract Address : </h6>
            <span className="w-text">
              {" "}
              0x0345FAB41E60e71bE6c80D279bf6396f46D630f1
            </span>
            <img src={symbolImg} className="symbol-img" width="100" alt="" />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
            <div className="welcome-meter">
              <img
                draggable="false"
                className="comp-img"
                src={Computer1}
                alt=""
              />
              {/* <img
                draggable="false"
                className="supportImg floating-anim"
                src={Trade}
                alt="image"
              /> */}
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 mt-s">
            <div className="who-we-contant">
              <div className="dream-dots text-left fadeInUp" data-aos="fade-up">
                <span className="gradient-text">{titleUp}</span>
              </div>
              <h4 className="fadeInUp" data-aos="fade-up">
                {titleDown}
              </h4>
              <p className="fadeInUp" data-aos="fade-up">
                {textUp}
              </p>
              <p className="fadeInUp" data-aos="fade-up">
                {textDown}
              </p>
              <a
                className="btn more-btn mt-15 fadeInUp"
                data-aos="fade-up"
                href="#"
              >
                {link}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
